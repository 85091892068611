@font-face {
    font-family: "Outfit";
    src: url("../font/Outfit-Regular.ttf");
}
@font-face {
    font-family: "Outfit-Bold";
    src: url("../font/Outfit-Bold.ttf");
}

@font-face {
    font-family: "Outfit-Light";
    src: url("../font/Outfit-Light.ttf");
}

@font-face {
    font-family: "Outfit-Medium";
    src: url("../font/Outfit-Medium.ttf");
}

@font-face {
    font-family: "Montserrat";
    src: url("../font/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("../font/Montserrat-Bold.ttf");
}

* {
    box-sizing: border-box;
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: var(--color-level1);
    font-family: 'Outfit Helvetica Neue', Helvetica, Tahoma, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'Microsoft YaHei', 'WenQuanYi Micro Hei',
    sans-serif;
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE 10+ */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  * ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  
  input {
    user-select: text;
  }
  
  div[tabindex],
  span[tabindex] {
    outline: none;
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
  }
  
  input {
    border: none;
    appearance: none;
    box-shadow: none;
  }
  
  textarea {
    resize: none;
  }
  
  button {
    border: none;
  }
  
  button, input, select, textarea {
    margin: 0;
    padding: 0;
    outline: 0;
    background: 0 0;
  }
  
  ul,
  ol,
  li,
  em {
    list-style: none;
    font-style: normal;
  }
  
  .markdown-body li {
    list-style: disc;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
  
  html,
  body {
    height: 100%;
    font-family: Outfit;
    /* overflow: hidden; */
  }