.animate__hand {
    transform: translate(-80px, 10px);
    transition: transform 2s;
}

@keyframes btcCoin{
	0%{
        opacity: 0.8;
            transform: rotate(0);
        }
        50%{
        opacity: 1;
            transform: rotate(-180deg);
        }
        100%{
        opacity: 0.8;
            transform: rotate(-360deg);
        }
}
@keyframes ethCoin{
	0%{
        opacity: 0.8;
            transform: rotate(0);
        }
        50%{
        opacity: 1;
            transform: rotate(180deg);
        }
        100%{
        opacity: 0.8;
            transform: rotate(360deg);
        }
}

@keyframes eggCoin{
	0%{
    opacity: 0.8;
		transform: translate(0,0);
	}
	50%{
    opacity: 1;
		transform: translate(0px,-40px);
	}
	100%{
    opacity: 0.8;
		transform: translate(0,0);
	}
}

@keyframes hand{
	0%{
    opacity: 0.8;
        transform: translate(0,0);
    }
    50%{
    opacity: 1;
        transform: translate(0px,-40px);
    }
    100%{
    opacity: 0.8;
        transform: translate(0,0);
    }
}

@keyframes common{
	0%{
    opacity: 0.8;
        transform: rotate(0);
    }
    50%{
    opacity: 1;
        transform: rotate(45deg);
    }
    100%{
    opacity: 0.8;
        transform: rotate(0);
    }
}
@keyframes commonTwo{
	0%{
    opacity: 0.8;
        transform: rotate(0);
    }
    50%{
    opacity: 1;
        transform: rotate(-45deg);
    }
    100%{
    opacity: 0.8;
        transform: rotate(0);
    }
}
@keyframes yellowAni{
	0%{
    opacity: 0.8;
        transform: rotate(0);
    }
    50%{
    opacity: 1;
        transform: rotate(180deg);
    }
    100%{
    opacity: 0.8;
        transform: rotate(360deg);
    }
}

.animate_btc {
    /* transform: scale(1); */
    transition: transform 2s;
    animation: btcCoin 1.2s linear;
}
.animate_eth {
    /* transform: scale(1); */
    transition: transform 2s;
    animation: ethCoin 1.2s linear;
}
.animate_coin-one {
    transform: scale3d(1, 1, 1)!important;
    transition: transform 2s;
}
.animate_coin-one-two {
    transform: rotate(90deg);
    transition: transform 2s;
}
.animate_coin-three-two {
    transform: rotate(178deg);
    transition: transform 2s;
}
.animate__title p, .animate__text p {
    -webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)!important;
    -moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)!important;
    -ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)!important;
    transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)!important;
    transition: transform 2s;
}
.animate_egg {
    transition: transform 2s;
    animation: eggCoin 1.2s linear;
}
.animate_yellow {
    /* transform: rotate(360deg); */
    transition: transform 2s;
    animation: yellowAni .8s linear;
}
.animate_handup {
    transition: transform 2s;
    animation: hand 1.2s linear;
}

.animate_common {
    transition: transform 2s;
    animation: common 1.2s linear;
}
.animate_commonTwo {
    transition: transform 2s;
    animation: commonTwo 1.2s linear;
}

